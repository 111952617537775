<template>
  <div class="tag-composition"
       :style="{'--container-padding':tagContainerStyle.padding + 'px'}"
       :class="{
          editing:mode !== TagCompositionMode.DISPLAY
       }"
       ref="selfRef"
  >
    <div class="tags-container"
         ref="tagContainer"
    >
      <div class="ctc-tag-wrapper"
           :style="{'--tag-margin-bottom':tagStyle.marginBottom + 'px',
       '--tag-margin-right':tagStyle.marginRight + 'px'
       }"
           :ref="getTagWrappers.bind(this,tagData.id)"
           :key="tagData.id"
           v-for="tagData in tagsDataCopy">
        <CertificateTag
            :ref="getTagRefs.bind(this,tagData.id)"
            :tag-data="tagData"
            :voucherRequired="tagData.voucherRequired"
            :show-close-btn="showCloseBtn"
            :preventModifyResolution="preventWrapperModifyResolution ?? preventModifyResolution"
            @closeBtnClicked="handleTagCloseBtnClicked"
            @preventChangeMode="preventChangeMode = $event"
        />
      </div>
      <input v-show="nameInputVisible"
             :style="{
           '--name-input-height':nameInputStyle.height + 'px',
           'pointer-events': !tagNameInputOpened ? 'none' : 'unset'
         }"
             ref="nameInput"
             class="tag-name-input"
             :class="inputClasses"
             v-model="nameInputValue"
             @focus="handleNameInputFocused"
             @keydown="handleNameInputKeydown"
             :placeholder="tagsDataCopy.length > 0 ? '' : placeholder">
    </div>
    <teleport :to="listTeleportTo">
      <div class="composition-tag-list-popper-wrapper"
           :style="{
        'z-index':listZIndex,
        '--container-padding':tagContainerStyle.padding + 'px',
        '--tag-margin-bottom':tagStyle.marginBottom + 'px',
       '--tag-margin-right':tagStyle.marginRight + 'px',
      }"
           :class="{
           open: popperVisible,
           opened: popperOpened
         }"
           ref="popperRef">
        <div class="composition-tag-list-popper"

             @transitionend="handleTransitionEnd">
          <p class="popper-tip" :class="{shadow:showPopperTipBottomShadow}">{{listPlaceholder}}</p>
          <div class="ctlp-scroll custom-scroller" ref="popperScrollRef"
               :style="{
                 '--list-max-height':listMaxHeight + 'px'
             }"
          >
            <TagList
                :custom-tags-data="customTagsData"
                :system-tags-data="systemTagsData"
                :keyword="nameInputValue.trim()"
                @appendSelectedTagData="handleAppendSelectedTagData"
                @createNewTagData="handleCreateNewTagData"
                @settingMenuToggled="handleSettingMenuToggled"
                :name-input-ref="nameInput"
                :visible="popperVisible"
                :setting-teleport-to="listTeleportTo"
                ref="tagListRef"
                @onDrag="handleListItemDragging"
            ></TagList>
          </div>
        </div>
      </div>
    </teleport>

  </div>
</template>

<script>
import MetaTag from "./MetaTag";
import { isEqual } from 'lodash';
import CertificateTag from "./CertificateTag";
import {
  eventsName,
  TagCompositionMode,
  TagListItemSettingMenuMode,
  TagStatus,
  TagStyleStatic,
  TagType
} from "./configure";
import TagList from "./TagList";
import {
  ref,
  watch,
  onBeforeUpdate,
  reactive,
  toRefs,
  nextTick,
  onMounted,
  unref,
  computed,
  provide,
  onBeforeUnmount,
} from "vue";
import {
  BriefEmitter,
  colorLog,
  deepClone,
  DomEventListenerManager,
  uuidGen
} from "../../util";
import anime from "../../assets/js/anime.es";
import { createPopper  } from '@popperjs/core';
import * as request from "../../api/api";
import {judgeIsSystemTag} from "./util";
import {ElMessage} from "element-plus";
export default {
  name: "TagComposition",
  components: {TagList,CertificateTag},
  props:{
    selectableTagsData:{
      required:true,
      default:[
        // {
        //   type:TagType.NEED_RESOLVE,
        //   id:0,
        //   name:'上传发票',
        //   voucherRequired:true,
        //   status:false
        // },
      ]
    },
    selectedTagsData:{
      required:false,
      default:[]
    },
    externalRequests:{
      required:true,
      default:{
        addTag:null, //添加标签,并且关联到目标实体(如 合同,日历,模板)
        removeTagRelation:null, //取消关联目标实体
        completeTag:null, //完成和目标实体关联的待办tag
        cancelTagCompleteStatus:null //取消完成和目标实体关联的待办tag
      }
    },
    listZIndex:{
      required:false,
      default:'auto'
    },
    listWidth:{
      required:false,
      default:'auto'
    },
    listPlaceholder:{
      required:false,
      default:'请选择或创建标签'
    },
    listTeleportTo:{
      required:false,
      default:'#app',
    },
    listMaxHeight:{
      required:false,
      default: 450
    },
    placeholder:{
      required:false,
      default:'搜索标签...'
    },
    inputClasses:{
      required:false,
      default:''
    },
    singletonMode:{
      required:false,
      default:false
    },
    createNewTagDataDefaultType:{
      required:false,
      default:TagType.NORMAL,
    },
    settingMenuModeType:{
      required:false,
      default:TagListItemSettingMenuMode.UNIVERSAL
    },
    preventWrapperModifyResolution:{
      required:false,
      default:undefined
    },
    disableAutoChangeMode: {
      required: false,
      default: false,
    }
  },
  setup(props,ctx){
    const propsRefs = toRefs(props);
    const nameInputValue = ref('');
    let tagsDataCopy = ref([]);
    const emitter = new BriefEmitter();
    const customTagsData = computed(() => {
      return props.selectableTagsData.filter(td => !judgeIsSystemTag(td));
    })
    const systemTagsData = computed(() => {
      return props.selectableTagsData.filter(td => judgeIsSystemTag(td));
    });
    //注意这里的provide的数据没有响应式功能
    provide('emitter',emitter);
    provide('zIndex',props.listZIndex);
    provide('externalRequests',props.externalRequests);
    provide('createNewTagDataDefaultType',props.createNewTagDataDefaultType);
    provide('settingMenuModeType',props.settingMenuModeType);

    tagsDataCopy.value.push(...props.selectedTagsData);

    const diffPropsTagsData = async (newData) => {
      const [copyIds,tagsDataCopyMap] = tagsDataCopy.value.reduce((dataset,tagData) => {
        const [ids,map] = dataset;
        ids.push(tagData.id);
        map[tagData.id] = tagData;
        return [ids,map];
      },[ [], {} ])
      const newIds = newData.map(t => t.id);
      //diff
      const [newAppendTags,changedTags] = newData.reduce((dataset,tagData) => {
        const [newTags,changedTagsMap] = dataset;
        const isNewTagData = !copyIds.includes(tagData.id);
        if(isNewTagData){
          newTags.push(tagData);
        }else{
          for(let propName in tagData){
            const newPropValue = tagData[propName];
            const oldPropValue = tagsDataCopyMap[tagData.id][propName];
            if(!isEqual(newPropValue,oldPropValue)){
              changedTagsMap[tagData.id] = {
               ...changedTagsMap[tagData.id],
                [propName]: {
                  new: newPropValue,
                  old: oldPropValue
                }
              }
            }
          }
        }

        return [newTags,changedTagsMap];
      },[ [] , {} ]);
      const missedTags = tagsDataCopy.value.filter(tagData => !newIds.includes(tagData.id));
      tagsDataCopy.value.forEach(td => {
        const sameEntity = newData.find(ntd => ntd.id === td.id);
        if(sameEntity){
          Object.assign(td,sameEntity);
        }
      });

      if(Object.keys(changedTags).length){
        await animateTagPositions();
      }
      appendContainerTag(newAppendTags);
      removeContainerTag(missedTags);


      colorLog.orange('changedTags',changedTags);
      colorLog.blue('newAppendTags',newAppendTags);
      colorLog.red('missedTags',missedTags);
    }

    watch(propsRefs.selectedTagsData.value,(newData) => {
      diffPropsTagsData(newData);
    });
    watch(propsRefs.selectedTagsData,(newData,oldData)=>{
      colorLog({
        newData,oldData:deepClone(tagsDataCopy.value)
      })

      diffPropsTagsData(newData);
    })

    //其他状态
    const state = reactive({
      showCloseBtn:false,
      preventModifyResolution:false,
      tagNameInputOpened:false,
      popperVisible:false,
      popperOpened:false,
      preventChangeMode:false,
    })
    const nameInputVisible = computed( () => {
      return state.tagNameInputOpened || tagsDataCopy.value.length === 0
    })
    watch(nameInputVisible,(visible) => {
      if(tagsDataCopy.value.length === 0 && visible) {
        nextTick( () => applyStyleStatic(undefined,[],calculateTagsPosition({forceCalculateNameInputRect:true}).nameInputRect));
      }
    })

    // 标签展示区相关
    const tagContainer = ref(null);
    const nameInput = ref(null);
    const tagContainerStyle = reactive({
      padding:TagStyleStatic.containerPadding,
    });

    const tagStyle = reactive({
      marginRight:TagStyleStatic.containerPadding,
      marginBottom:TagStyleStatic.containerPadding,
      tagHeight:TagStyleStatic.containerPadding + TagStyleStatic.tagContentBoxHeight
    });
    const nameInputStyle = reactive({
      minWidth:90,
      height:24
    });
    const handleNameInputFocused = () => {
      openPopper();
    }
    const resetTagsPosition = () => {
      const calculatedInfo = calculateTagsPosition();
      applyStyleStatic(calculatedInfo.accumulateHeight,calculatedInfo.positions,calculatedInfo.nameInputRect);
    }

    let tagRefs = new Map();
    let tagWrappers = new Map();
    onMounted(resetTagsPosition);
    onBeforeUpdate(() => {
      tagWrappers = new Map();
      tagRefs = new Map();
    });

    const getTagWrappers = ref((id,node) => {
      tagWrappers.set(id,node);
    });
    const getTagRefs = ref((id,vnode) => {
      tagRefs.set(id,vnode);
    });

    const calculateTagsPosition = (options = {
      forceCalculateNameInputRect:false
    }) => {
      const tagContainerWidth = tagContainer.value.clientWidth;
      const tagsInfo = Array.from(tagRefs.entries()).sort((a,b) => {
        let bIsBehind = false;
        const bWrapper = tagWrappers.get(b[0]);
        let nextNode = tagWrappers.get(a[0]);
        if(!bWrapper || !nextNode) return false;

        while(nextNode){
          if(nextNode === bWrapper){
            bIsBehind = true;
            break;
          }
          nextNode = nextNode.nextElementSibling
        }
        return !bIsBehind ? 1 : -1;
      }).reduce((prevInfo,[tagId,tagRef],index) => {
        if(!tagRef) return prevInfo;
        let {height:tagHeight,width:tagWidth} = tagRef.api.getTagIntactMarginBoxSize();
        if(!state.showCloseBtn){
          tagWidth -= TagStyleStatic.closeBtnWidth;
        }
        const needWrap = prevInfo.endPosition.left + tagWidth > tagContainerWidth;
        const tagPosition = {
          top: needWrap ? prevInfo.accumulateHeight : prevInfo.endPosition.top,
          left: needWrap ? tagContainerStyle.padding : prevInfo.endPosition.left,
          locatedWrapper: tagWrappers.get(tagId)
        }
        const endPosition = {
          top:tagPosition.top,
          left: tagPosition.left + tagWidth + TagStyleStatic.containerPadding
        }

        const positions = {
          ...prevInfo.positions,
          [tagId] : tagPosition,
        }

        let accumulateHeight = needWrap ? tagHeight + prevInfo.accumulateHeight + tagStyle.marginBottom : prevInfo.accumulateHeight;

        return {
          endPosition,
          accumulateHeight,
          positions,
        }
      },{
        endPosition:{top:tagContainerStyle.padding,left:tagContainerStyle.padding},
        accumulateHeight:tagContainerStyle.padding + tagStyle.tagHeight,
        positions:{},
      })

      let nameInputRect;
      if(mode.value !== TagCompositionMode.DISPLAY || options.forceCalculateNameInputRect){
        nameInputRect = {};
        const nameInputNeedWrap = (tagsInfo.endPosition.left + nameInputStyle.minWidth) > tagContainerWidth;
        const prevAccumulateHeight = tagsInfo.accumulateHeight;
        tagsInfo.accumulateHeight = nameInputNeedWrap ? (prevAccumulateHeight + nameInputStyle.height + tagContainerStyle.padding) : tagsInfo.accumulateHeight;

        const redundantWidth = getComputedStyle(tagContainer.value).boxSizing === 'border-box' ? TagStyleStatic.containerPadding : 0;
        nameInputRect.wrapped = nameInputNeedWrap;
        nameInputRect.top = nameInputNeedWrap ? prevAccumulateHeight : tagsInfo.endPosition.top;
        nameInputRect.left = nameInputNeedWrap ? tagContainerStyle.padding : tagsInfo.endPosition.left;
        nameInputRect.width = nameInputNeedWrap ? (tagContainerWidth - redundantWidth) : (tagContainerWidth - tagsInfo.endPosition.left - redundantWidth);
      }

      return {
        ...tagsInfo,
        nameInputRect
      }
    }

    let clearNameInputHeight;
    const applyStyleStatic = (containerHeight,tagWrapperPositions,nameInputRect) => {
      tagContainer.value.style.setProperty('height',containerHeight + 'px');
      popperInstance?.update();

      tagWrappers.forEach((tagWrapperNode,tagId) => {
        const tagWrapperPosition = tagWrapperPositions[tagId];
        if(!tagWrapperPosition) return;
        tagWrapperNode.style.setProperty('top', tagWrapperPosition.top + 'px');
        tagWrapperNode.style.setProperty('left', tagWrapperPosition.left + 'px');
      });
      if(nameInputRect){
        nameInput.value.style.setProperty('width',nameInputRect.width + 'px');
        nameInput.value.style.setProperty('left',nameInputRect.left + 'px');
        nameInput.value.style.setProperty('top',nameInputRect.top + 'px');

        if(nameInputRect.wrapped){
          clearNameInputHeight = () => {
            tagContainer.value.style.setProperty('height',containerHeight - nameInputStyle.height - tagContainerStyle.padding + 'px');
            popperInstance?.update();
            clearNameInputHeight = null;
          }
        }else{
          clearNameInputHeight = null;
        }
      }
    };
    //nameInput值改变会改变popper的高度,这时刷新下popper的位置
    watch(nameInputValue,() => {
      popperInstance?.update();
    })
    //nameInput获取焦点
    const tryToFocusNameInput = () => {
      if(mode.value === TagCompositionMode.DISPLAY) return;
      nameInput.value.focus();
    }

    // 动画相关
    const createTimeLine = () => {
      return anime.timeline({
        easing: 'easeOutExpo',
        duration: 300,
      });
    }
    onMounted(() => {
      createTimeLine().add({
        targets:Array.from(tagWrappers.values()),
        opacity:1,
        scale:[0,1],
        duration:600,
      })
    })

    const animateTagPositions = () => {
      let proxyRes;
      const pro = new Promise(res => proxyRes = res);
      const {positions:calculatedPositions,accumulateHeight,nameInputRect} = calculateTagsPosition();
      applyStyleStatic(accumulateHeight,{},nameInputRect);
      const flattenValues = Object.values(calculatedPositions);
      createTimeLine().add({
        targets:flattenValues.map(v => v['locatedWrapper']),
        left:function (el, i){
          return flattenValues[i].left;
        },
        top:function (el, i){
          return flattenValues[i].top;
        },
        complete(){
          proxyRes();
        }
      })

      return pro;
    }

    const appendContainerTag = (newTagsData) => {
      const newIds = newTagsData.map(t => t.id);
      const alreadyExistedTags = tagsDataCopy.value.filter(tc => newIds.includes(tc.id));
      const alreadyExistedTagWrappers = alreadyExistedTags.map(t => tagWrappers.get(t.id));

      createTimeLine().add({
        targets:alreadyExistedTagWrappers,
        scale:[1.2,1],
        duration:600,
        easing: 'spring(1, 80, 10, 0)',
      });

      if(!newTagsData.length || alreadyExistedTags.length !== 0) return;
      tagsDataCopy.value.push(...newTagsData);
      props.selectedTagsData.push(...newTagsData);

      nextTick(() => {
        const newIds = newTagsData.map(t => t.id);
        const {positions:calculatedPositions,nameInputRect,accumulateHeight} = calculateTagsPosition();
        const [wrapperPositions,wrappers] = newIds.reduce((arr,id) => {
          return [{...arr[0],[id]:calculatedPositions[id]}, [...arr[1],tagWrappers.get(id)]]
        },[{},[]]);
        applyStyleStatic(accumulateHeight,wrapperPositions,nameInputRect);
        createTimeLine().add({
          targets:wrappers,
          opacity:1,
          scale:[0,1],
        })
        tryToFocusNameInput()
      });
    }

    const removeContainerTag = (removeTagsData) => {
      let proxyResolve , pro = new Promise(r =>proxyResolve = r );
          if(!removeTagsData.length) {
            proxyResolve();
            return ;
          };
          const [wrappers,ids] = removeTagsData.reduce((arr,t) => {
            const [wrappers,ids] = arr;
            wrappers.push(tagWrappers.get(t.id));
            ids.push(t.id)
            return [wrappers,ids];
          },[[],[]]);

          createTimeLine().add({
            targets:wrappers,
            opacity:0,
            scale:[1,0],
            complete: function (anim){
              tagsDataCopy.value = tagsDataCopy.value.filter(t => !ids.includes(t.id));
              props.selectedTagsData.splice(0,props.selectedTagsData.length,...tagsDataCopy.value);
              nextTick(() => {
                animateTagPositions();
                tryToFocusNameInput()
                proxyResolve();
              })
            }
          })
      return pro;
    }


    // 模式
    const mode = ref(TagCompositionMode.DISPLAY);
    const changeMode = (assignMode, openPopper = true) => {
      if(typeof assignMode !== 'undefined'){
        if(assignMode === TagCompositionMode.DISPLAY){
          mode.value = TagCompositionMode.DISPLAY;
        }else if(assignMode === TagCompositionMode.INTACT_EDIT){
          mode.value = TagCompositionMode.INTACT_EDIT;
        }
        return;
      }

      if(mode.value !== TagCompositionMode.DISPLAY){
        mode.value = TagCompositionMode.DISPLAY;
      }else{
        mode.value = TagCompositionMode.INTACT_EDIT;
      }

    }


    //监听模式改变
    watch(mode,(changeToMode) => {
      if(changeToMode === TagCompositionMode.DISPLAY){
        state.preventModifyResolution = false;
        state.tagNameInputOpened = false;
        closePopper();
        state.showCloseBtn = false;
        // tagStyle.marginRight = TagStyleStatic.containerPadding + TagStyleStatic.closeBtnWidth;
        clearNameInputHeight && clearNameInputHeight();
        animateTagPositions();
      }else{
        state.preventModifyResolution = true;
        state.tagNameInputOpened = true;
        openPopper();
        state.showCloseBtn = true;

        // tagStyle.marginRight = TagStyleStatic.containerPadding;
        nextTick(() => {
          // const calculatedInfo = calculateTagsPosition();
          // applyStyleStatic(calculatedInfo.accumulateHeight,[],calculatedInfo.nameInputRect);
          animateTagPositions();
          tryToFocusNameInput();
          resetPopperWidth();
        });
      }

      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: state.popperVisible },
        ],
      }));

      ctx.emit('modeChanged',mode.value,state.popperVisible);
    });

    //操作
    const handleTagCloseBtnClicked = async (tagData) => {
      const res = await props.externalRequests.removeTagRelation(tagData.id);
      if(res.data.code !== 0 && res.data.code !== 200){
        ElMessage.error(res.data.msg);
      }else{
        await removeContainerTag([tagData]);
      }
    }

    //popper下拉框
    const popperRef = ref(null);
    let popperInstance;
    const resetPopperWidth = () => {
      if(props.listWidth !== 'auto'){
        popperRef.value.style.setProperty('width', props.listWidth + 'px');
      }else{
        popperRef.value.style.setProperty('width', tagContainer.value.clientWidth + 'px');
      }
      popperInstance.update();
    }

    onMounted(() => {
      popperInstance = createPopper(unref(tagContainer),unref(popperRef),{
        placement:'bottom',
        modifiers:[
          {
            name:'flip',
            options: {
              boundary:document.body,
              fallbackPlacements:['left-start','right-start'],
              rootBoundary: 'document',
            },
          }
        ],
        onFirstUpdate: state => {
          resetPopperWidth();
        }
      });
    });

    const singletonModeProcessAppendOperation = async (tagData) => {
      if(props.singletonMode){
        const differentTagsData = tagsDataCopy.value.filter(td => tagData.id !== td.id);
        if(differentTagsData.length){
          await props.externalRequests.removeTagRelation(differentTagsData[0].id);
          await removeContainerTag(differentTagsData);
        }
      }
    }

    const handleAppendSelectedTagData = async (tagData) => {
      await singletonModeProcessAppendOperation(tagData);
      tagData.status = TagStatus.pending;
      appendContainerTag([tagData]);
      ctx.emit('containerTagDataAppended',tagData);
      nameInputValue.value = '';
    }

    const handleCreateNewTagData = async (tagData) => {
      await singletonModeProcessAppendOperation(tagData);
      appendContainerTag([tagData]);
      props.selectableTagsData.push(tagData);
      nameInputValue.value = '';
    }

    const handleTransitionEnd = (event) => {
      if(event.propertyName !== 'transform') return;
      setTimeout(() => {
        state.popperOpened = state.popperVisible;
      },event.elapsedTime);

      if(state.popperVisible){
        emitter.emit(eventsName.TAG_LIST_OPENED);
      }
    }

    emitter.on(eventsName.TAG_DATA_SETTING_CHANGED,async (tagData) => {
      if(tagData.name.length === 0) return;
      let changedTagData = tagsDataCopy.value.find(td => td.id === tagData.id);
      const res = await request.updateTagType(tagData.id,tagData);

      if(changedTagData && res.data.code == 0){
        Object.assign(changedTagData,tagData);
        nextTick(animateTagPositions);
      }
    });

    emitter.on(eventsName.DELETED_FROM_SETTING,(tagData)=>{
      let deleteTagData = tagsDataCopy.value.find(td => td.id === tagData.id);
      if(deleteTagData){
        setTimeout(() => removeContainerTag([deleteTagData]),200);
      }

      let deleteIndex = props.selectableTagsData.findIndex(td => td.id === tagData.id);
      props.selectableTagsData.splice(deleteIndex,1);
    });
    emitter.on(eventsName.PENDING_TAG,(tagData) => {
      nextTick(animateTagPositions);
    });
    emitter.on(eventsName.RESOLVE_TAG,(tagData) => {
      nextTick(animateTagPositions);
      ctx.emit('tagResolved',tagData);
    });

    const handleSettingMenuToggled = (visible) => {
      state.preventChangeMode = visible;
    }

    //下拉框滚动窗口
    const popperScrollRef = ref(null);
    const showPopperTipBottomShadow = ref(false);
    onMounted(() => {
      domEventListenerManager.registerListener(popperScrollRef.value,'scroll',(event) => {
        const scrollTop = popperScrollRef.value.scrollTop;
        showPopperTipBottomShadow.value = scrollTop != 0;
      })
    });
    //关闭或开启下拉框
    const closePopper = () => {
      state.popperVisible = false;
    }
    const openPopper = () => {
      state.popperVisible = true;
    }

    const togglePopper = () => {
      if(state.popperVisible){
        closePopper();
      }else{
        openPopper();
      }
    }

    //nameInput监听 keyCode 40:↓  38:↑ 13:Enter
    const tagListRef = ref(null);
    let taskProcessing = false;
    const deleteTaskQueue = [];
    const flushDeleteTaskQueue = async () => {
      if(taskProcessing || deleteTaskQueue.length === 0) return;
      taskProcessing = true;
      while(deleteTaskQueue.length){
        const task = deleteTaskQueue.shift();
        await task();
      }
      taskProcessing = false;
    }
    const handleNameInputKeydown = (event) => {
      if(['ArrowUp','ArrowDown','Enter'].includes(event.key)){
        event.preventDefault();
      }
      if(event.key === 'Backspace'){
        if(!nameInputValue.value){
          deleteTaskQueue.push( async () => {
            if(tagsDataCopy.value.length){
              const removeTags = tagsDataCopy.value.slice(-1);
              await props.externalRequests.removeTagRelation(removeTags[0].id);
              return removeContainerTag(removeTags);
            }
          });
          flushDeleteTaskQueue();
        }
      }
    }

    let canScroll = true
    let scrollAnimation = null;
    const autoScroll = (variation) => {
      if(!canScroll) return;
      canScroll = false;
      scrollAnimation = anime({
        targets:popperScrollRef.value,
        scrollTop: popperScrollRef.value.scrollTop + variation,
        duration:200,
        easing: 'easeInOutCubic',
        complete:function() {
          canScroll = true;
        }
      })
    }

    const handleListItemDragging = (value,getDraggingNode) => {
      if(value){
        domEventListenerManager.registerListener(popperScrollRef.value,'mousemove',function (event) {
          const {height:nodeHeight,y:nodeY} = this.getBoundingClientRect();
          const offsetY = event.clientY - nodeY;
          if(offsetY < 30){
            autoScroll(-34);
          }else if(nodeHeight < offsetY + 30){
            autoScroll(34);
          }
        });
      }else{
        domEventListenerManager.removeListener(popperScrollRef.value ,'mousemove');
        scrollAnimation?.pause();
      }
    }

    //全局事件监听
    const selfRef = ref(null);
    const domEventListenerManager = new DomEventListenerManager();
    domEventListenerManager.registerListener(window,'mousedown',(event) => {
      const target = event.target;
      if(selfRef.value.contains(target) || popperRef.value.contains(target) || state.preventChangeMode) return;

      if(!props.disableAutoChangeMode){
        changeMode(TagCompositionMode.DISPLAY);
      }else{
        closePopper();
      }
    });

    // 27:Esc
    domEventListenerManager.registerListener(window,'keydown',(event) => {
      if(event.keyCode == 27 && !state.preventChangeMode){
        event.preventDefault();
      }else{
        return;
      }
      if(mode.value !== TagCompositionMode.DISPLAY){
        props.disableAutoChangeMode || changeMode(TagCompositionMode.DISPLAY);
      }
    });

    onBeforeUnmount(() => domEventListenerManager.removeListener());

    const api = {
      resetTagsPosition,
      changeMode,
      getSelectedTagsData: () => {
        return tagsDataCopy;
      },
      openPopper,
      closePopper,
    }
    return {
      colorLog,
      TagCompositionMode,
      selfRef,
      mode,
      emitter,
      tagsDataCopy,
      customTagsData,
      systemTagsData,
      tagContainer,
      nameInput,
      popperRef,
      popperScrollRef,
      showPopperTipBottomShadow,
      tagListRef,
      tagContainerStyle,
      nameInputStyle,
      tagStyle,
      nameInputValue,
      nameInputVisible,
      ...toRefs(state),
      getTagWrappers,
      getTagRefs,
      handleTagCloseBtnClicked,
      handleAppendSelectedTagData,
      handleCreateNewTagData,
      handleNameInputKeydown,
      handleTransitionEnd,
      handleSettingMenuToggled,
      handleListItemDragging,
      handleNameInputFocused,
      api
    }
  }
}
</script>

<style scoped>
.tag-composition{
  --container-padding:5px;
  --tag-margin-right:5px;
  --tag-margin-bottom:5px;
  flex:1;
}
.tag-composition.editing{
  border-radius: 4px;
  background: rgba(150, 150, 150, 0.1);
}
.tags-container{
  position: relative;
  box-sizing: border-box;
  padding: var(--container-padding) 0 0 var(--container-padding) ;
}

.ctc-tag-wrapper{
  z-index: 1;
  display: inline-block;
  opacity: 0;
  position: absolute;
  font-size: 0;
  padding: 0 var(--tag-margin-right) var(--tag-margin-bottom) 0;
}

.tag-name-input{
  --name-input-height:24px;

  all:unset;
  position: absolute;
  left:var(--container-padding);
  height:var(--name-input-height);
  text-align: left;
}
.tag-name-input.disabled{
  pointer-events: none;
}

.composition-tag-list-popper-wrapper:not(.opened):not(.open) {
  pointer-events: none;
  transition: .1s ease-in;
}
.composition-tag-list-popper-wrapper.opened,
.composition-tag-list-popper-wrapper.open
{
  pointer-events: unset;
}

.composition-tag-list-popper-wrapper.open:not(.opened){
  transition: .1s ease-out;
}


.composition-tag-list-popper{
  --list-max-height: 450px;

  opacity: 0;
  transform: scale(.8);
  overscroll-behavior: none;
  transform-origin: top;
  box-sizing: border-box;
  background: white;
  overflow-y: hidden;
  border: 1px solid var(--gray-1);
  border-radius: 4px;
  box-shadow: var(--box-shadow-1);
  padding: 0 0 var(--container-padding) var(--container-padding);
  transition:opacity .1s ease-in,transform .2s ease-in;
}
.ctlp-scroll{
  overflow-y: auto;
  max-height: var(--list-max-height);
}

.open .composition-tag-list-popper{
  transition:opacity .3s ease-out,transform .2s var(--cubic-bounce-1);

  opacity:1;
  transform: scale(1);
}
.popper-tip{
  margin: 0;
  user-select: none;
  text-align: left;
  color:var(--font-gray);
  padding-bottom: calc(var(--container-padding) / 2);
  padding-top: var(--container-padding);
  background-color: white;
  z-index: 1;
  transition:.2s ease-out;
}
.popper-tip.shadow{
  box-shadow: 0 5px 3px -2px var(--near-white-2);
}
</style>
