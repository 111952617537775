<template>
  <div class="meta-tag-list-item"
  :class="{
    focused:isFocused,
    setting:isSetting
  }"
  >
    <div class="mtli-icon-area">
      <slot name="icon"></slot>
    </div>
    <div class="mtli-tag-area" @click="$emit('tagAreaClicked')">
      <slot name="default">

      </slot>
    </div>

    <div class="mtli-setting-area" ref="settingAreaRef" :class="settingAreaClassName"  @click="$emit('settingClicked',$refs.settingAreaRef,tagData)">
      <slot name="setting"></slot>
    </div>

  </div>
</template>

<script>
import {computed, onMounted, reactive, ref, toRef, toRefs, unref, watch} from "vue";

export default {
  name: "TagListItem",
  components: {},
  props:{
    tagData:{
      required:true
    },
    settingAreaClassName:{
      required:false
    },
    isFocused:{
      required:false,
      default:false
    },
    isSetting:{
      required:false,
      default:false,
    }
  },
  setup(props,ctx){
    const state = reactive({

    });
    return {
      ...toRefs(state),
    }
  }
}
</script>

<style scoped>
.meta-tag-list-item{
  --list-item-padding:5px;

  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: var(--list-item-padding) 0;
}
.meta-tag-list-item:first-child{
  padding-top: var(--list-item-padding);
}
.meta-tag-list-item:last-child{
  padding-bottom: var(--list-item-padding);
}

.mtli-icon-area{
  flex: 0 0 28px;
  text-align: center;
  padding-right: 5px;
}
.mtli-tag-area{
  flex: 1 1 auto;
  min-width: 0;
  text-align: left;
  cursor:pointer;
  position: relative;
}
.mtli-tag-area::before {
  position: absolute;
  content: '';
  width: 100%;
  height: calc(100% + var(--list-item-padding) * 2);
  top: calc(-1 * var(--list-item-padding));
}

.mtli-setting-area{
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 4px;
  opacity: 0;
}
.meta-tag-list-item.setting .mtli-setting-area:not(:empty),
.mtli-setting-area:hover:not(:empty){
  background:var(--gray-4);
}
.mtli-setting-area:hover:empty{
  pointer-events: none;
}
.meta-tag-list-item.setting .mtli-setting-area,
.meta-tag-list-item:hover .mtli-setting-area{
  opacity: 1;
}

</style>
