<template>
  <div class="tag-item-setting">
    <input class="sp-name-input" ref="nameInputRef" v-model="tagDataCopy.name" @focus="isNameInputFocusing = true" @blur="isNameInputFocusing = false">
    <div class="ctis-delete-option" @click="visibilities.deleteDialog = true" >
      <i class="el-icon-delete"></i>
      删除
    </div>
    <hr style="background: var(--gray-1);opacity: .5;margin:5px 0;"/>
    <div class="ctis-tag-type-container" v-if="settingMenuModeType === TagListItemSettingMenuMode.UNIVERSAL">
      <span class="ttc-label">类型</span>
      <div class="ttc-radio">
        <div class="ttc-radio-item">
          <input class="ttc-radio-virtual"
                 :checked="tagDataCopy.type === TagType.NORMAL"
                 v-model="tagDataCopy.type"
                 id="ttc-radio-noResolve" type="radio" name="tagType" :value="TagType.NORMAL">
          <label class="radio-item-check-icon" @click="handleChangeTagType(TagType.NORMAL)"></label>
          <label class="radio-item-check-label" @click="handleChangeTagType(TagType.NORMAL)">普通标签</label>
        </div>
        <div class="ttc-radio-item need-resolve">
          <input class="ttc-radio-virtual"
                 :checked="tagDataCopy.type === TagType.NEED_RESOLVE"
                 v-model="tagDataCopy.type"
                 id="ttc-radio-resolve" type="radio" name="tagType" :value="TagType.NEED_RESOLVE">
          <label class="radio-item-check-icon" @click="handleChangeTagType(TagType.NEED_RESOLVE)"></label>
          <label class="radio-item-check-label" @click="handleChangeTagType(TagType.NEED_RESOLVE)">待办标签</label>
          <div class="ttc-certificate" v-if="tagDataCopy.type === TagType.NEED_RESOLVE">
            <span style="margin-left: 10px">需要凭证</span>
            <el-switch style="margin-left: 3px"
                       size="small"
                       ref="switchRef"
                       @change="handleVoucherRequiredChanged"
                       v-model="tagDataCopy.voucherRequired"/>
          </div>
        </div>
      </div>
    </div>
    <div class="ttc-certificate" v-if="tagDataCopy.type === TagType.NEED_RESOLVE && settingMenuModeType === TagListItemSettingMenuMode.ONLY_NEED_RESOLVE">
      <span>需要凭证</span>
      <el-switch style="margin-left: 3px"
                 size="small"
                 ref="switchRef"
                 @change="handleVoucherRequiredChanged"
                 v-model="tagDataCopy.voucherRequired"/>
    </div>

    <el-dialog
        v-model="visibilities.deleteDialog"
        :width="600"
        :append-to-body="true"
        title="确认删除"
        custom-class="ct-dialog"
    >
      <div class="ct-confirm-tip-content" deletedialog>
        <svg-icon class="confirm-tip-icon" name="notion" width="20" height="20"></svg-icon>
        您确定要删除此标签吗？{{allTagRelatedEntities.length ? '删除后，以下合同将不再与此标签关联：' : ''}}
      </div>

      <template #footer>
        <el-button type="default" @click="handleDeleteOperate(false)">取消</el-button>
        <el-button type="primary" @click="handleDeleteOperate(true)">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog
        v-model="visibilities.switchTypeDialog"
        :width="600"
        :append-to-body="true"
        title="确认修改"
        custom-class="ct-dialog"
    >
      <div class="ct-confirm-tip-content" modifydialog>
        <svg-icon class="confirm-tip-icon" name="notion" width="20" height="20"></svg-icon>
        您确定要修改此标签吗？{{allTagRelatedEntities.length ? '修改后，将会影响到以下关联了此标签的合同：' : ''}}
      </div>

      <template #footer>
        <el-button type="default" @click="handleSwitchOperate(false)">取消</el-button>
        <el-button type="primary" @click="handleSwitchOperate(true)">确定</el-button>
      </template>
    </el-dialog>

    <div style="display: none">
      <teleport :to="relatedEntitiesTableTeleportTo">
        <MetaListTable
            v-if="allTagRelatedEntities.length"
            :table-data="allTagRelatedEntities"
            :table-height="300">
          <el-table-column
              prop="title"
              label="标题"
              min-width="150"
              sortable="custom"
              sort-by="title"
              show-overflow-tooltip>
            <template #default="scope">
              <div class="related-entity-icon" :class="getContractStatusStyleClassName(scope.row.approvalStatus)"></div>
              {{scope.row.title}}
            </template>
          </el-table-column>
          <el-table-column
              prop="otherParties"
              label="当事人"
              min-width="100"
              sortable="custom"
              sort-by="other_parties"
          >
            <template #default="scope">
                <span v-if="!scope.row.otherParties || getOtherParties(scope.row.otherParties).length == 0">

                </span>
              <div  class="otherParties"
                    v-else>
                <el-tooltip
                    :disabled="scope.row.otherParties.length === 1"
                    :content="getOtherParties(scope.row.otherParties).join('、')"
                    :show-after="0"
                    :hide-after="0"
                    placement="top"
                >
                  <el-tag size="small" type="info" class="info-tag">
                    {{
                      composeOtherParties(scope.row.otherParties)
                    }}
                  </el-tag>
                </el-tooltip>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              prop="updateTime"
              label="最近更新"
              width="150"
              sortable="custom"
              sort-by="update_time"
          >
            <template #default="scope">
              {{
                transformTimeInfoForList(scope.row.updateTime,Date.now())
              }}
            </template>
          </el-table-column>
        </MetaListTable>
      </teleport>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, watch, ref, unref, toRef, inject, reactive, onBeforeUnmount, nextTick} from "vue";
import {deepClone, DomEventListenerManager,colorLog, transformTimeInfoForList} from "../../util";
import SvgIcon from "../SvgIcon/svgIcon";
import {ElMessage} from 'element-plus';
import {TagType, eventsName, TagListItemSettingMenuMode} from "./configure";
import * as request from "../../api/api";
import MetaListTable from "../list/MetaListTable";
import {getOtherParties, composeOtherParties, getContractStatusStyleClassName} from "../../constant/contract";

export default {
  name: "TagItemSetting",
  components: {MetaListTable, SvgIcon},
  props:{
    settingTagData:{
      required:true,
    },
    visible:{
      required:false,
    },
  },
  setup(props,ctx){
    const tagDataCopy = ref(null);
    const visibilities = reactive({
      deleteDialog:false,
      switchTypeDialog:false
    })
    const copyData = () => {
      props.settingTagData.voucherRequired = !!props.settingTagData.voucherRequired;
      tagDataCopy.value = deepClone(props.settingTagData);
    }
    const settingMenuModeType = inject('settingMenuModeType');
    watch(toRef(props,'settingTagData'),(tagData) => {
      copyData()
    },{
      deep:true,
      immediate:true
    });
    watch(toRef(props,'visible'),(visible) => {
      if(!visible) return;
      copyData();
    })


    const switchRef = ref(null);

    const parentEmitter = inject('emitter');
    //删除或修改某标签,标签关联的所以实体(模板,合同...)
    const allTagRelatedEntities = ref([]);
    const relatedEntitiesTableTeleportTo = ref('#app');
    const updateAllTagRelatedEntities = async () => {
      const {data: {data: contractEntitiesCount}} = await request.getAllContractTagRelation(tagDataCopy.value.id);
      const {data:{data: publishedTemplateEntitiesCount}} = await request.getAllPublishedTemplateTagRelation(tagDataCopy.value.id);
      const {data:{data:{content:accessibleEntities}}} = await request.getResourceList({
        pageIndex: 0,
        pageSize: 99999,
        filterType: 0,
        tags: tagDataCopy.value.name,
        resourceType:0,
        trashed: false,
        sort:'desc',
        orderBy:'update_time'
      });
      if(contractEntitiesCount + publishedTemplateEntitiesCount === accessibleEntities.length){
        allTagRelatedEntities.value = accessibleEntities;
      }else{
        allTagRelatedEntities.value = [];
      }
    }

    watch(toRef(visibilities,'deleteDialog'),async (visible) => {
      if(visible){
        try{
          await updateAllTagRelatedEntities();
          await nextTick();
          relatedEntitiesTableTeleportTo.value = '.ct-confirm-tip-content[deletedialog]';
        }catch (e){
          ElMessage.error('不能执行此操作');
          return;
        }
      }


      parentEmitter.emit(eventsName.CHANGE_PREVENT_STATE,visible);
    },{
      flush:"pre"
    })
    watch(toRef(visibilities,'switchTypeDialog'),async (visible) => {
      if(visible){
        await updateAllTagRelatedEntities();
        await nextTick();
        relatedEntitiesTableTeleportTo.value = '.ct-confirm-tip-content[modifydialog]'
      }
      parentEmitter.emit(eventsName.CHANGE_PREVENT_STATE,visible);
    },{
      flush:"pre"
    })


    const confirmChangeTagData = () => {
      if(!props.visible) return;

      parentEmitter.emit(eventsName.TAG_DATA_SETTING_CHANGED,unref(tagDataCopy));
    }

    const domEventListenerManager = new DomEventListenerManager();
    onMounted(() => {
      domEventListenerManager.registerListener(window,'keydown',(event) => {
        if(['Enter','ArrowUp','ArrowDown'].includes(event.key)){
          switch (event.key){
            case 'Enter':
              confirmChangeTagData();
              break;
          }
          event.preventDefault();
        }
      })
    })
    onBeforeUnmount(() => domEventListenerManager.removeListener());

    const handleDeleteOperate = async (isConfirm) => {
      const removeRelationRemote = async (request) => {
        const res = await request(tagDataCopy.value.id);
        if(res.data.code !== 0){
          throw new Error(res.data.msg);
        }
      }

      if(isConfirm){
        await removeRelationRemote(request.removeAllContractTagRelation);
        await removeRelationRemote(request.removeAllPublishedTemplateTagRelation);
        await removeRelationRemote(request.removeAllDateRelation);

        const res = await request.deleteTag(tagDataCopy.value.id);
        if(res.data.code === 0){
          ElMessage.success(`${props.settingTagData.name} 删除成功!`);
          parentEmitter.emit(eventsName.DELETED_FROM_SETTING,unref(tagDataCopy));
        }else{
          ElMessage.error(`${res.data.msg}!`);
        }
      }else{
        //
      }
      visibilities.deleteDialog = false;
    }

    //切换标签类型
    let tendToSwitchType;
    const handleChangeTagType = (tagType) => {
      visibilities.switchTypeDialog = true;
      tendToSwitchType = tagType;
    }
    const handleSwitchOperate = (isConfirm) => {
      if(isConfirm){
        tagDataCopy.value.type = tendToSwitchType;
        parentEmitter.emit(eventsName.TAG_DATA_SETTING_CHANGED,unref(tagDataCopy),false);
      }else{
        //
      }
      visibilities.switchTypeDialog = false;
    }

    const handleVoucherRequiredChanged = () => {
      parentEmitter.emit(eventsName.TAG_DATA_SETTING_CHANGED,unref(tagDataCopy),false);
    }

    const nameInputRef = ref(null);
    const isNameInputFocusing = ref(false);
    const api = {
      isInputSelecting: () => {
        return tagDataCopy.value.name.search(window.getSelection().toString()) > -1 && isNameInputFocusing.value;
      }
    }


    return {
      switchRef,
      nameInputRef,
      TagType,
      TagListItemSettingMenuMode,
      tagDataCopy,
      visibilities,
      settingMenuModeType,
      isNameInputFocusing,
      allTagRelatedEntities,
      relatedEntitiesTableTeleportTo,
      handleDeleteOperate,
      handleSwitchOperate,
      handleChangeTagType,
      handleVoucherRequiredChanged,
      getOtherParties,
      composeOtherParties,
      transformTimeInfoForList,
      getContractStatusStyleClassName,
      api
    }
  }
}
</script>
<style>
.ct-dialog{
  text-align: center;
}
</style>
<style scoped>
.tag-item-setting{
  font-size: 12px;
  text-align: left;
}
.sp-name-input{
  all:unset;
  width: 100%;
  box-sizing: border-box;
  background: var(--gray-1);
  border: 1px solid #d2d1d0;
  border-radius: 4px;
  padding: .5ex 1ch;
  text-align: left;

}
.sp-name-input:focus{
  box-shadow: rgba(46, 170, 220, 0.7) 0px 0px 0px 1px inset,
  rgba(46, 170, 220, 0.4) 0px 0px 0px 2px !important;
}
.ctis-icon{
  font-size: 16px;
}
.ctis-delete-option{
  text-align: left;
  padding: 5px 0;
  margin:5px 0;
  cursor:pointer;
  user-select: none;

}
.ctis-delete-option:hover{
  background:var(--gray-1);
}
.ttc-label{
  color:var(--font-gray);
  cursor: default;
}
.ctis-tag-type-container{
}
.ttc-radio{
  margin-top: 5px;
}
.ttc-radio-item{
  height: 2em;
  display: flex;
  align-items: center;
}
.radio-item-check-icon{
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border: 1px solid var(--gray-4);
  padding: 2px;
  background-clip: content-box;
}
.ttc-radio-virtual{
  position: absolute;
  clip: rect(0,0,0,0);
}
.ttc-radio-virtual:checked + .radio-item-check-icon{
  border-color: var(--lightBlue);
  background-color: var(--lightBlue);
}
.radio-item-check-label{
  margin-left: 1ch;
}
.ttc-certificate{
  display: flex;
  align-items: center;
}
.related-entity-icon{
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 10px;
  vertical-align: -.2ex;
}
.otherParties{
  padding: 10px 0 10px 0;
  height: 46px;
  display: flex;
  align-items: center;
}
</style>
