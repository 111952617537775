<template>
  <div class="meta-tag-list" ref="selfRef">
    <div class="mtl-draggable-area" ref="draggableAreaRef" >
      <div class="mtl-popper-anchor" ref="popperAnchorRef"></div>
      <teleport :to="settingTeleportTo">
        <div class="mtl-setting-popper-wrapper"
             :class="{
                open:settingPopperState.visible,
                opened:settingPopperState.opened
             }"
             :style="{
             'z-index':zIndex
             }"
             ref="popperRef">
          <div class="mtl-setting-popper" @transitionend="handlePopperTransitionEnd">
            <TagItemSetting
                v-if="currentSettingTagData"
                :setting-tag-data="currentSettingTagData"
                :visible="settingPopperState.visible"
                ref="settingRef"
            />
          </div>
        </div>
      </teleport>

      <div class="mtl-draggable-items" v-if="isDraggableItemsRendered">
        <TagListItem
            v-for="(tagData,index) in currentDraggableAreaTagsData"
            :key="tagData.id"
            @tagAreaClicked="handleTagAreaClicked(tagData)"
            class="mtl-tag-list-item"
            :class="{
              dragging
            }"
            :tag-data="tagData"
            :ref="getTagListItemWrappers.bind(this,tagData.id)"
            @mouseenter="changeFocusIndex(index)"
            @settingClicked="(...args) => handleSettingClicked(index,$event,...args)"
            :setting-area-className="settingClassName"
            :is-focused="currentArrowFocusTagIndex == index"
            :is-setting="currentSettingTagData?.id === tagData.id && settingPopperState.visible"
        >
          <template #icon>
            <svg-icon class="list-item-drag-icon"
                      @mousedown="startDragging(tagData.id,$event)"
                      width="14" height="14" name="drag"/>
          </template>

          <CertificateTag
              :keyword="keyword"
              :tag-data="tagData"
              :show-close-btn="false"
              :prevent-modify-resolution="true"
              :truncate="false"
          ></CertificateTag>

          <template #setting v-if="!judgeIsSystemTag(tagData)">
            <svg-icon class="list-item-setting-icon" width="14" height="14" name="setting"></svg-icon>
          </template>
        </TagListItem>
      </div>
    </div>
    <div class="mtl-create-tag-area" v-if="showCreateTagOption">
      <TagListItem @click="handleCreateNewTagClicked"
                           class="mtl-tag-list-item"
                           :class="{
            focused:currentArrowFocusTagIndex == maxIndex
          }"
                           :tag-data="currentNewTagData"
                           @mouseenter="currentArrowFocusTagIndex = currentDraggableAreaTagsData.length"
      >
        <template #icon>
          <span class="create-option-label">创建</span>
        </template>
        <CertificateTag
            :tag-data="{
              name:keyword,
              type:createNewTagDataDefaultType
            }"
            :show-close-btn="false"
            :prevent-modify-resolution="true"
            :truncate="false"
        ></CertificateTag>
      </TagListItem>
    </div>
  </div>
</template>

<script>
import TagListItem from "./TagListItem";
import SvgIcon from "../SvgIcon/svgIcon";

import {
  computed,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted,
  reactive,
  ref,
  toRef,
  unref,
  watch,
  inject,
  toRefs, nextTick
} from "vue";
import CertificateTag from "./CertificateTag";
import {judgeIsSystemTag, matchString} from "./util";
import {
  colorLog,
  deepClone,
  DomEventListenerManager,
  generateProxyPromiseWrappedFunction,
  getBrowser, isDangerousContent,
  uuidGen
} from "../../util";
import {eventsName, TagStatus, TagType} from "./configure";
import anime from "../../assets/js/anime.es";
import { createPopper  } from '@popperjs/core';
import TagItemSetting from "./TagItemSetting";
import * as request from "../../api/api";
import {ElMessage} from "element-plus";

export default {
  name: "TagList",
  components: {TagItemSetting, TagListItem,SvgIcon,CertificateTag},
  props:{
    customTagsData:{
      required:false,
      default:[]
    },
    systemTagsData:{
      required:false,
      default:[]
    },
    keyword:{
      required:false,
      default:''
    },
    visible:{
      required:false
    },
    settingTeleportTo:{
      required:false,
      default:'#app',
    }
  },
  setup(props,ctx){
    const nameInputHasText = computed(() => {
      return !!props.keyword;
    })

    const zIndex = inject('zIndex') || 'auto';
    const createNewTagDataDefaultType = inject('createNewTagDataDefaultType');
    const propRefs = toRefs(props);
    const externalRequests = inject('externalRequests');

    const tagsDataCopy = computed( () => {
      return props.customTagsData.map(t => {
        return {
          ...t,
          status:null
        }
      }).sort((a,b) => (b.sortIndex - a.sortIndex));
    });
    const allTagsData = computed(() => {
      return [...props.systemTagsData, ...props.customTagsData];
    });

    const currentOrders = reactive({});
    const currentVirtualOrders = reactive({});
    watch(allTagsData,(newVal) => {
      const len = newVal.length;
      newVal.sort((a,b) => {
        return b.sortIndex - a.sortIndex;
      }).forEach((tagData,index) => {
        currentOrders[tagData.id] = tagData.sortIndex;
        currentVirtualOrders[tagData.id] = len - 1 - index;
      });
    },{immediate:true});


    const matchedTagsData = computed(() => {
      const matchedArr = [];
      allTagsData.value.forEach((tagData) => {
        const info = matchString(tagData.name,props.keyword);
        if(info.matched){
          tagData.allMatched = info.allMatched;
          matchedArr.push(tagData);
        }
      })
      return matchedArr;
    });
    const currentDraggableAreaTagsData = computed( () => {
      const value = (nameInputHasText.value ? matchedTagsData.value : tagsDataCopy.value).sort((pre,post) => {
        return currentOrders[post.id] - currentOrders[pre.id];
      });
      return value
    });

    const showCreateTagOption = computed(() => {
      return !!props.keyword && matchedTagsData.value.every(info => !info.allMatched);
    });

    const currentNewTagData = computed( () => {
      if(showCreateTagOption.value){
        return {
          id:uuidGen(),
          type:TagType.NORMAL,
          name:props.keyword,
          sortIndex:0
        }
      }
      return null;
    });

    const handleTagAreaClicked = async (tagData) => {
      await externalRequests.addTag(tagData.name,createNewTagDataDefaultType);
      ctx.emit('appendSelectedTagData',tagData);
    }

    const handleCreateNewTagClicked = () => {
      createNewTagData();
    }
    const createNewTagData = async () => {
      if(isDangerousContent(currentNewTagData.value.name,true)){
        return;
      }
      const res = await externalRequests.addTag(currentNewTagData.value.name,createNewTagDataDefaultType);
      const mewTagData = res.data.data;
      ctx.emit('createNewTagData',mewTagData);
    }

    /**
     * 键盘上下键选择搜索或创建相关
     */
    const selfRef = ref(null);
    const domEventListenerManager = new DomEventListenerManager();
    onMounted(() => {
      domEventListenerManager.registerListener(window,'keydown',(event) => {
        if(event.key === 'Escape'){
          closePopper();
        }
        if(!props.visible || settingPopperState.visible) return;
        if(['Enter','ArrowUp','ArrowDown'].includes(event.key)){
          switch (event.key){
            case 'Enter':
              selectFocusedTagListItem();
              break;
            case 'ArrowUp':
              inputArrowUp()
              break;
            case 'ArrowDown':
              inputArrowDown()
              break;
          }
          event.preventDefault();
        }
      })
    });

    onBeforeUnmount(() => domEventListenerManager.removeListener());

    //聚焦的tagListItem的index相关
    const currentArrowFocusTagIndex = ref(-1);
    watch(toRef(props,'keyword'),(newValue) => {
      if(!newValue) {
        currentArrowFocusTagIndex.value = -1;
        return;
      }
      currentArrowFocusTagIndex.value = 0;
    });
    const maxIndex = computed(() => {
      return showCreateTagOption.value ? currentDraggableAreaTagsData.value.length : currentDraggableAreaTagsData.value.length - 1;
    });

    const inputArrowUp = () => {
      const changedIndex = Number(currentArrowFocusTagIndex.value) - 1;
      changeFocusIndex(changedIndex < 0 ? maxIndex.value : changedIndex)
    }
    const inputArrowDown = () => {
      const changedIndex = Number(currentArrowFocusTagIndex.value) + 1;
      changeFocusIndex(changedIndex % (maxIndex.value + 1));
    }
    const selectFocusedTagListItem = async () => {
      if(currentArrowFocusTagIndex.value == maxIndex.value && showCreateTagOption.value){
        createNewTagData();
      }else{
        const tagData = currentDraggableAreaTagsData.value[currentArrowFocusTagIndex.value];
        await externalRequests.addTag(tagData.name,createNewTagDataDefaultType);
        ctx.emit('appendSelectedTagData',tagData);
      }
    }

    //做一层封装,根据情况判断是否改变index
    const changeFocusIndex = (index) => {
      if(dragging.value) return;
      currentArrowFocusTagIndex.value = index;
    }
    onMounted(() => {
      domEventListenerManager.registerListener(selfRef.value,'mouseleave',() => {
        changeFocusIndex(-1);
      });
    })

    //拖动相关
    const draggableAreaRef = ref(null);
    const isDraggableItemsRendered = ref(true);
    const dragging = ref(false);
    let tagListItemWrappers = new Map();
    onBeforeUpdate(() => {
      tagListItemWrappers = new Map();
    });
    const getTagListItemWrappers = ref((id,vnode) => {
      tagListItemWrappers.set(id,vnode);
    });
    const currentDraggingTagListItemWrapper = ref(null);
    let tempOrders;
    let startTopPosition;
    let draggingNode;
    const startDragging = (draggingTagId,mousedownEvent) => {
      dragging.value = true;
      let {accumulateHeight:draggableAreaHeight,positions:currentPositions} = resetTagListItemsPosition();
      tempOrders = {...currentOrders};

      currentDraggingTagListItemWrapper.value = tagListItemWrappers.get(draggingTagId);
      draggingNode = currentDraggingTagListItemWrapper.value.$el;
      const offsetParent = draggingNode.offsetParent;
      draggingNode.style.setProperty('z-index','1');
      const offset = draggingNode.getBoundingClientRect().y - mousedownEvent.clientY;
      startTopPosition = mousedownEvent.clientY - offsetParent.getBoundingClientRect().y;
      let isAscend;
      let positionOccupiedTagId;
      let positionRange;

      domEventListenerManager.registerListener(window,'mousemove',(moveEvent) => {
        //主要的换位逻辑
        moveEvent.preventDefault();
        const currentMouseTopPosition = moveEvent.clientY - offsetParent.getBoundingClientRect().y;
        let currentTop = currentMouseTopPosition + offset;
        if(currentTop < 0){
          currentTop = 0;
        }else if(draggableAreaHeight < currentTop + draggingNode.clientHeight){
          currentTop = draggableAreaHeight - draggingNode.clientHeight;
        }

        const currentCenter = currentTop + draggingNode.clientHeight / 2;
        //范围判断,判断拖动元素的水平中线现在的垂直位置在原先的列表里哪个listItem的范围内
        Object.entries(currentPositions).find(([id,position],index) => {
          if(id === draggingTagId) return false;
          const isAtRange = currentCenter <= position.bottom && currentCenter >= position.top;
          if(!isAtRange) return false;
          //ok,找到了要换位的目标
          const originOrder = currentVirtualOrders[draggingTagId];
          const changedOrder = currentVirtualOrders[id];
          isAscend = (startTopPosition - currentMouseTopPosition) > 0;
          //如果还是占用同一个tag,就取消下面的操作
          if(id === positionOccupiedTagId) return;
          positionOccupiedTagId = id;
          positionRange = {
            front:[],
            middle:[],
            rear:[]
          };
          const frontThreshold = Math.max(originOrder,changedOrder);
          const rearThreshold = Math.min(originOrder,changedOrder);
          const includesOrders = Object.entries(currentVirtualOrders).reduce((prevInfos,[id,order]) => {
            let isFrontRange = frontThreshold < order;
            let isAtOrderRange = frontThreshold > order && rearThreshold < order;
            let isRearRange = rearThreshold > order;
            switch (true){
              case isFrontRange:
                positionRange.front.push(id);
                break;
              case isAtOrderRange:
                positionRange.middle.push(id);
                break;
              case isRearRange:
                positionRange.rear.push(id)
                break;
            }

            if(!isAtOrderRange) return prevInfos;
            return {
              ...prevInfos,
              [id]:(order - (isAscend ? 1 : -1))
            };
          },{});
          //记录下当前改变后的orders信息
          tempOrders = {
            ...currentVirtualOrders,
            ...includesOrders,
            [draggingTagId]: changedOrder,
            [id]: isAscend ? changedOrder - 1 : changedOrder + 1,
          }
          animateTagListItemPositions([draggingTagId],tempOrders);
          return true;
        })

        draggingNode.style.setProperty('top',currentTop + 'px');

      },{capture:true});

      domEventListenerManager.registerListener(window,'mouseup', async (event) => {
        domEventListenerManager.removeListener(window,'mousemove');
        domEventListenerManager.removeListener(window,'mouseup');
        await animateTagListItemPositions([],tempOrders);

        const changedOrder = currentOrders[positionOccupiedTagId];
        currentOrders[draggingTagId] = changedOrder +  (isAscend ? 1 : -1);
        let changes = [
          {
            id:draggingTagId,
            sortIndex:currentOrders[draggingTagId]
          }
        ];
        let isValid;
        if(isAscend){
          isValid = positionRange.front.every(id => currentOrders[id] > currentOrders[draggingTagId]);
        }else{
          isValid = positionRange.rear.every(id => currentOrders[id] < currentOrders[draggingTagId]);
        }
        if(!isValid) {
          const variation = isAscend ? 1 : -1;
          let logInfos = [];
          positionRange[isAscend ? 'front' : 'rear'].forEach((id,index) => {
            logInfos.push({
              id,
              name:allTagsData.value.find(td => td.id == id).name,
              o:currentOrders[id],
              n:currentOrders[id] + variation,
            })
            currentOrders[id] = currentOrders[id] + variation;
            changes.push({
              id,
              sortIndex: currentOrders[id]
            });
          });
        }
        Object.entries(currentOrders).sort(([prevId,prevOrder],[rearId,rearOrder]) => {
          return rearOrder - prevOrder;
        }).forEach(([id,order],index)=> {
          currentVirtualOrders[id] = allTagsData.value.length - 1 - index;
        })
        isDraggableItemsRendered.value = false;
        await nextTick();
        isDraggableItemsRendered.value = true;
        request.updateTagsSortIndex(changes);
        dragging.value = false;
        removeStyleStatic && removeStyleStatic();
        draggingNode.style.removeProperty('z-index');
      },{capture:true});
    };

    watch(dragging,(value) => {
      ctx.emit('onDrag',value,() => draggingNode);
    });


    const calculateTagPositions = (orders) => {
      let idWrapperSet = Array.from(tagListItemWrappers.entries());
      if(orders){
        idWrapperSet = Array.from(tagListItemWrappers.entries()).sort((pro,post) => {
          const proOrder = orders[pro[0]];
          const postOrder = orders[post[0]];
          return postOrder - proOrder;
        });
      };
      return idWrapperSet.reduce((prevInfo,[id,nodeRef],index) => {
        const listItemNode = nodeRef.$el;
        const position = { top: prevInfo.endPosition.top,center:prevInfo.endPosition.top + listItemNode.clientHeight / 2,bottom: prevInfo.endPosition.top + listItemNode.clientHeight};
        const endPosition = {top: prevInfo.endPosition.top + listItemNode.clientHeight};
        const accumulateHeight = prevInfo.accumulateHeight + listItemNode.clientHeight;

        return {
          accumulateHeight,
          endPosition,
          positions:{...prevInfo.positions,[id]:position},
        }
      },{
        accumulateHeight:0,
        endPosition:{top:0},
        positions:{},
      });
    }
    let animation;
    const animateTagListItemPositions = (excludeTagIds,orders) => {
      let proxyResolve , pro = new Promise(r =>proxyResolve = r );

      const {positions} = calculateTagPositions(orders);
      let idWrapperSet = Array.from(tagListItemWrappers.entries()).filter(([id, wrapperRef]) => {
        return !excludeTagIds.includes(id);
      });
      const wrappers = [];
      const ids = [];
      idWrapperSet.forEach(([id, wrapperRef]) => {
        wrappers.push(wrapperRef.$el);
        ids.push(id)
      });
      animation?.pause();
      animation = anime({
        targets: wrappers,
        top: function (el, i) {
          return positions[ids[i]].top;
        },
        duration: 300,
        changeComplete: function () {
          proxyResolve();
        }
      })
      return pro;
    }

    let removeStyleStatic = null;
    const applyStyleStatic = (draggableAreaHeight,positions) => {
      draggableAreaRef.value.style.setProperty('height',draggableAreaHeight + 'px');
      Array.from(tagListItemWrappers.entries()).forEach(([id,nodeRef])=>{
        const listItemNode = nodeRef.$el;
        const {top} = positions[id];
        listItemNode.style.setProperty('top',top + 'px');
      })

      removeStyleStatic = () => {
        draggableAreaRef.value.style.removeProperty('height');
      }
    }


    const resetTagListItemsPosition = () => {
      const {accumulateHeight,positions} = calculateTagPositions();
      applyStyleStatic(accumulateHeight,positions);

      return {
        accumulateHeight,
        positions,
      }
    }

    //更多的setting
    const popperRef = ref(null);
    const popperAnchorRef = ref(null);
    const settingClassName = 'setting-rect';

    const settingPopperState = reactive({
      visible:false,
      opened:false,
      moving:false,
    });


    watch(toRef(settingPopperState,'visible'),(visible) => {
      ctx.emit('settingMenuToggled',visible);
    })

    let settingPopperInstance = ref(null);
    const parentEmitter = inject('emitter');

    parentEmitter.on(eventsName.TAG_LIST_OPENED,() => {
      settingPopperInstance.value.update();
    });
    onMounted(() => {
      settingPopperInstance.value = createPopper(unref(popperAnchorRef),unref(popperRef),{
        placement:'bottom-end',
        modifiers: [
          { name: 'eventListeners', enabled: false },

        ],
      });
    });

    const currentSettingTagData = ref(null);
    const handleSettingClicked = (index,event,settingArea,tagData) => {
      changeFocusIndex(index);
      currentSettingTagData.value = tagData;
      const offsetY = settingArea.getBoundingClientRect().y + settingArea.clientHeight - popperAnchorRef.value.getBoundingClientRect().y;
      settingPopperState.visible = true;
      settingPopperInstance.value.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true },
          { name: 'offset',
            options:{
              offset:[0,offsetY],
            }
          },
          {
            name: 'preventOverflow',
            options: {
              boundary:document.body,
              altAxis: true
            },
          },
        ],
      }));
      settingPopperInstance.value.update();
    }

    const handlePopperTransitionEnd = (event) => {
      if(event.propertyName !== 'transform') return;
      setTimeout(() => {
        if(getBrowser() === 'FF') return;
        settingPopperState.opened = settingPopperState.visible;
      },event.elapsedTime);
    };

    const closePopper = () => {
      settingPopperState.visible = false;
    }

    const preventMousedown = ref(false);
    parentEmitter.on(eventsName.CHANGE_PREVENT_STATE,(val) => {
      preventMousedown.value = val;
    });
    parentEmitter.on(eventsName.DELETED_FROM_SETTING,(tagData)=>{
      closePopper();
    })
    parentEmitter.on(eventsName.TAG_DATA_SETTING_CHANGED,(tagData,isClosePopper = true) => {
      if(allTagsData.value.find(td => (td.name === tagData.name && td.id !== tagData.id))){
        ElMessage.error('修改无效,命名重复');
      }else if(tagData.name.length === 0){
        ElMessage.error('修改无效,内容为空');
      }else{
        let changedTagData = allTagsData.value.find(td => td.id === tagData.id);
        Object.assign(changedTagData,tagData);
      }

      isClosePopper && closePopper();
    });

    const settingRef = ref(null);
    domEventListenerManager.registerListener(window,'click',(event) => {
      if(preventMousedown.value || settingRef.value?.api.isInputSelecting()) return;
      const isClickOnSameSettingBtn = (() => {
        if(!currentSettingTagData.value) return false;
        const currentSettingItem = tagListItemWrappers.get(currentSettingTagData.value.id);
        if(!currentSettingItem) return false;
        const clickedSettingBtn = event.target.closest(`.${settingClassName}`);
        return currentSettingItem.$el.contains(clickedSettingBtn);
      })();
      if(isClickOnSameSettingBtn){
        if(settingPopperState.visible){
          settingPopperState.visible = false;
          settingPopperState.opened = false;
          event.stopPropagation();
        }
        return;
      }

      //点击的是setting组件自己或另一个设置按钮
      if(popperRef.value.contains(event.target) || event.target.closest(`.${settingClassName}`)) return;
      settingPopperState.visible = false;
      settingPopperState.opened = false;
    },{capture:true});

    const api = {
      inputArrowUp,
      inputArrowDown,
      selectFocusedTagListItem,
    }
    return {
      selfRef,
      createNewTagDataDefaultType,
      draggableAreaRef,
      isDraggableItemsRendered,
      getTagListItemWrappers,
      zIndex,
      tagsDataCopy,
      matchedTagsData,
      showCreateTagOption,
      nameInputHasText,
      currentNewTagData,
      currentArrowFocusTagIndex,
      currentDraggableAreaTagsData,
      maxIndex,
      dragging,
      popperRef,
      popperAnchorRef,
      settingRef,
      settingPopperState,
      settingClassName,
      currentSettingTagData,
      settingPopperInstance,
      judgeIsSystemTag,
      handleTagAreaClicked,
      handleCreateNewTagClicked,
      startDragging,
      handleSettingClicked,
      handlePopperTransitionEnd,
      uuidGen,
      changeFocusIndex,
      api
    }
  }
}
</script>

<style scoped>
.meta-tag-list{

}
.list-item-drag-icon{
  cursor:grab;
}
.list-item-drag-icon.disabled{
  cursor: default;
  pointer-events: none;
}
.mtl-draggable-area{
  position: relative;
}
.create-option-label{
  font-size: 12px;
}
.mtl-tag-list-item.focused{
  background:var(--gray-1);
}
.mtl-tag-list-item.dragging{
  position: absolute;
  width: 100%;
  left: 0;
}

.mtl-setting-popper-wrapper{
  pointer-events: none;
  z-index: 1;
}
.mtl-setting-popper-wrapper.open{
  pointer-events: unset;
}
.mtl-setting-popper-wrapper.opened{
  transition: .2s var(--cubic-bounce-1);
}
.mtl-setting-popper{
  opacity: 0;
  transform: scale(.8);
  transform-origin: top;
  background: white;
  width: 200px;
  padding: 10px;
  border: 1px solid var(--gray-1);
  border-radius: 4px;
  box-shadow: var(--box-shadow-1);
  transition:opacity .1s ease-in,transform .2s ease-in;
}

.open .mtl-setting-popper{
  transition:opacity .3s ease-out,transform .2s var(--cubic-bounce-1);

  opacity:1;
  transform: scale(1);
}


</style>
